/**
 * Created by r4zi4l on 17.11.2021
 */

SpecialEnergyOfferWindow.prototype.onShow = function () {
    this.button.setScale(0);
    this.button.runAction(new cc.Sequence(
        new cc.DelayTime(0.25),
        new cc.ScaleTo(0.2, 1)
    ));

    this.rewardNode.setScale(0);
    this.rewardNode.runAction(new cc.Sequence(
        new cc.DelayTime(0.35),
        new cc.ScaleTo(0.2, 1)
    ));

    if (this.person) {
        this.person.y -= this.person.height;
        this.person.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.MoveBy(0.4, 0, this.person.height).easing(cc.easeBackOut())
        ));
    }
};

cleverapps.overrideStyles(cleverapps.styles.SpecialEnergyOfferWindow, {
    animation: {
        height: 850,

        x: { align: "center" },
        y: { align: "center", dy: 0 }
    },

    button: {
        width: 300,
        height: 120
    },

    reward: {
        x: { align: "center", dx: 550 },
        y: { align: "center", dy: 240 },
        rotation: 9
    }
});
