/**
 * Created by r4zi4l on 23.08.2021
 */

WorkerView.prototype.miningEffects = function (code) {
    switch (code) {
        default: return {
            animation: "chop",
            sound: cleverapps.skins.getSlot("workerSound") || bundles.mineable.urls.mining_start_effect
        };
    }
};

WorkerView.prototype.buildingEffects = function () {
    return {
        sound: bundles.buildable.urls.build_effect
    };
};
